<template>
  <div>
    <div class="bottom-container" ref="bottom">
      <img src="./img/dino.png" alt="" class="dino">
      <div class="content clearfix">
        <div class="text">
          <p><span class="free">免费</span>领取一节</p>
          <p>英语试听课</p>
        </div>
        <div class="btn" @click="handleShowEntry">立即领取</div>
      </div>
      <div class="arrow" @click="goback">
        <img src="./img/arrow-up.png" alt="">
      </div>
    </div>
    <loginIframe v-if="showLogin" @close="handleClose" :isPopup="true" :channelId="channelId" :type="1"></loginIframe>
  </div>
</template>
<script>
import loginIframe from '@/components/loginIframe.vue';
import vkTrack from '@/utils/_vktrack'
import tools from '@/utils/tools'

export default {
  name: 'cBottom',
  data() {
    return {
      boottomHeight: 0,
      showLogin: false
    };
  },
  computed: {
    channelId() {
      if(location.href.includes('home') || location.href.split('.cn')[1] === '/') {
        return '14771735'
      } else if(location.href.includes('bigstar')){
        return '14771738'
      } else if(location.href.includes('teacher')){
        return '14771742'
      } else if(location.href.includes('oversea')){
        return '14771746'
      } else if(location.href.includes('ai')){
        return '14771749'
      } else if(location.href.includes('culture')){
        return '14771752'
      } else if(location.href.includes('bobi')){
        return '14771754'
      } else if(location.href.includes('steps')){
        return '14771755'
      } else if(location.href.includes('ets')) {
        return '14771764'
      }
    }
  },
  components:{
    loginIframe
  },
  methods: {
    handleScroll() {
      let scrollTopHeight = '';
      const documentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      scrollTopHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTopHeight < windowHeight || scrollTopHeight + windowHeight >= (documentHeight - this.boottomHeight - 60)) {
        this.$refs.bottom.classList.remove('active');
        this.$refs.bottom.classList.add('hideBottom');
      } else {
        this.$refs.bottom.classList.add('active');
        this.$refs.bottom.classList.remove('hideBottom');
      }
    },
    goback() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleShowEntry() {
      const path = tools.getMatch(location.pathname)
      vkTrack.click(`${path}_parent_h5_bottom_signup_click_box`)
      this.showLogin = true
      document.body.style.overflow = 'hidden';
    },
    handleClose() {
      this.showLogin = false
      document.body.style.overflow = 'visible';
    }
  },
  mounted() {
    this.boottomHeight = this.$refs.bottom.offsetHeight;
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style style lang="less" scoped>
  @import "../base.less";
  @keyframes showBottom{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  @keyframes hideBottom{
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  }
  .bottom-container{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    margin: 0 auto;
    width: 100%;
    height: 67px;
    background-color: rgba(248, 84, 21, 1);
    opacity: 0;
    display: none;
    &.active{
      display: block;
      animation: showBottom 0.25s ease-in 1 forwards;
    }
    &.hideBottom{
      display: none;
      animation: hideBottom 0.25s ease-in 1 forwards;
    }
    .dino{
      width: 74px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .content{
      padding-left: 88px;
      margin-top: 9px;
      .text{
        float: left;
        font-size: 20px;
        line-height: 26px;
        color: rgba(255, 255, 255, 1);
        font-weight: 500;
        .free{
          color: rgba(255, 211, 6, 1);
        }
      }
      .btn{
        float: left;
        margin-left: 20px;
        margin-top: 6px;
        background: linear-gradient(343.94deg, #FD6D28 -100.3%, #FFD306 51.89%);
        width: 83px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: rgba(47, 51, 56, 1);
        text-align: center;
        border-radius: 8px;
        font-weight: 500;
      }
    }
    .arrow{
      position: absolute;
      right: 0;
      bottom: 0;
      width: 54px;
      height: 100%;
      img{
        width: 100%;
      }
    }
  }
</style>
