<!--
  通用的swiper组件
-->
<template>
  <div class="comp-swiper">
    <van-swipe :loop="true" :autoplay="3000" :width="216" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <div class="item">
          <img :src="item.imgUrl" />
          <div class="desc">
            <div v-for="(text, index) in item.textList" :key="index" class="text">
              <slot name="text" :text="text">{{ text }}</slot>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
  }
}
</script>

<style lang="less" scoped>
@import "../base.less";

.comp-swiper {
  .item {
    padding-left: 16px;
    img {
      width: 100%;
      height: 132px;
      border-radius: 16px;
    }
    .desc {
      margin-top: 16px;
      >.text {
        .font-title();
        text-align: center;
      }
    }
  }
}
</style>