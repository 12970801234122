/**
 * 各类静态辅助方法
 */

// 项目根路径，正式环境下会基于nginx进行配置，但多页项目模式下在本地只能是斜杠(/)
// 所以才有 jumpPath 和 isCurrPath 这两个方法
const basePath = '/web-v2'

// 是否本地开发环境，即是否包含端口信息
let isLocalDev = () => location.host.match(/:\d+/)

export const jumpPath = (path) => {
  if (!isLocalDev()) {
    // 针对首页特殊处理
    if (path === '/home') {
      path = '/'
    } else {
      path = basePath + path
    }
  }
  location.href = path
}

export const isCurrPath = (path) => {
  let currPath = location.pathname
  if (!isLocalDev()) {
    currPath = currPath.replace(basePath, '')
  }

  // 针对跟路径(首页)进行单独处理
  if (path === '/home' && ['', '/'].includes(currPath)) {
    return true
  }

  return path === currPath
}
