import { render, staticRenderFns } from "./videoPlayer.vue?vue&type=template&id=01201504&scoped=true"
import script from "./videoPlayer.vue?vue&type=script&lang=js"
export * from "./videoPlayer.vue?vue&type=script&lang=js"
import style0 from "./videoPlayer.vue?vue&type=style&index=0&id=01201504&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01201504",
  null
  
)

export default component.exports