<template>
  <div class="footer-container">
    <h4 class="kefu-title">客服热线</h4>
    <div class="info clearfix">
      <div class="tel">
        <a href="tel:95175100"><img src='./img/tel-icon.png' alt=""> 95175100</a>
        <span>周一至周日 08:00～22:00</span>
      </div>
      <div class="zixun-btn js-online-service" @click="onlineService">在线咨询</div>
    </div>
    <ul class="list">
      <li>
        <img src="./img/logo1.png" alt=""/>
      </li>
      <li>
        <img src="./img/logo2.png" alt=""/>
      </li>
      <li>
        <img src="./img/logo3.png" alt=""/>
      </li>
      <li>
        <img src="./img/logo4.png" alt=""/>
      </li>
    </ul>
    <div class="detail">
      <p>© VIPKID 京ICP证 150842号<img src="./img/police.png" alt=""><span class="police">京公网安备 11010102001980号</span></p>
      <p>北京大米科技有限公司 违法和不良信息举报电话：95175100</p>
      <p>出版物经营许可证(京零)字第海170127号</p>
      <p>广播电视节目制作经营许可证(京)字第08921号</p>
      <p>举报邮箱：VKreport@vipkid.com.cn<img src="./img/red-flag.png" alt="" class="flag" /></p>
    </div>
  </div>
</template>
<script>
import vkTrack from '@/utils/_vktrack'
import tools from '@/utils/tools'

export default {
  name: 'cFooter',
  data() {
    return {
    };
  },
  methods: {
    onlineService() {
      const path = tools.getMatch(location.pathname)
      vkTrack.click(`${path}_parent_h5_footer_consult_box`)
    },
  },
};
</script>
<style scoped lang="less">
@import "../base.less";
.footer-container{
  position: relative;
  widows: 100%;
  padding: 24px 16px 16px;
  background: rgba(38, 38, 39, 1);
  color: #ffffff;
  .kefu-title{
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    &:before{
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 20px;
      height: 2px;
      background: #f85415;
    }
  }
  .info{
    padding-top: 30px;
    .tel{
      float: left;
    }
    .zixun-btn{
      float: right;
      width: 100px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      background-color: #f85415;
      text-align: center;
      border-radius: 8px;
    }
    a{
      position: relative;
      display: inline-block;
      padding-left: 35px;
      font-size: 24px;
      color: #ffffff;
      line-height: 36px;
      font-weight: 350;
      img{
        position: absolute;
        top: 3px;
        left: 0;
        width: 27px;
      }
    }
    span{
      display: block;
      padding-top: 8px;
      font-size: 12px;
      color: rgba(153, 153, 153, 1);
      font-weight: 400;
    }
  }
  .list{
    display: flex;
    padding-top: 30px;
    li{
      width: 80px;
      padding-right: 8px;
      img{
        width: 100%;
      }
      &:last-child{
        padding-right: 0;
      }
    }
  }
  .detail{
    padding-top: 17px;
    p{
      color: rgba(119, 119, 119, 1);
      font-size: 12px;
      line-height: 18px;
      font-weight: 300;
      padding-bottom: 8px;
      &:last-child{
        padding-bottom: 56px;
      }
      .flag{
        float: right;
        width: 24px;
      }
    }
    img{
      width: 16px;
      margin-left: 5px;
      margin-right: 3px;
    }
  }
}

</style>
