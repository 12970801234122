<template>
  <div ref="bg" :class="{'bg': showMenu}">
    <div class="nav" ref="nav" :class="[{'showNav': isScroll}, {'pb4': showMenu}]">
      <div class="left">
        <img src="./img/logo.png" alt="" class="logo" @click="jumpHome">
      </div>
      <div class="right">
        <span class="btn login" v-if="!showMenu" @click="goLogin">登录</span>
        <!-- <span class="btn register" v-if="!showMenu" @click="goSignup">注册</span> -->
        <img src="./img/menu.png" alt="" class="menu" v-if="!showMenu" @click="showMenu=true">
        <img src="./img/close.png" alt="" class="menu" v-else @click="showMenu=false">
      </div>
    </div>
    <div class="nav-menu" v-show="showMenu" ref="navMenu">
      <a v-for="(item, index) in menuNames"
        :key="index" :class="{'active': isActive(item)}"
        @click="jump(item)"
      >{{item.name}}</a>
    </div>
  </div>
</template>
<script>
import { jumpPath, isCurrPath } from '@/utils/helper'
import vkTrack from '@/utils/_vktrack'
import tools from '@/utils/tools'

export default {
  name: 'cHeader',
  data() {
    return {
      isScroll: false,
      showMenu: false,
      menuNames: [
        { name: '首页', path: '/home' },
        { name: 'VIPKID 大咖英语', path: '/bigstar' },
        { name: '北美师资', path: '/teacher' },
        { name: 'VIPKID 1对1(海外版)', path: '/oversea' },
        { name: 'VIPKID 主修智学AI体系', path: '/ai' },
        { name: 'VIPKID 双语文化素养', path: '/culture' },
        { name: 'VIPKID 波比星球', path: '/bobi' },
        { name: 'ETS官方合作', path: '/ets' },
        { name: '如何上课', path: '/steps' },
      ],
    }
  },
  watch: {
    // 导航栏菜单出现时禁止滚动
    showMenu(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        this.$nextTick(() => {
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          this.$refs.bg.style.height = `${scrollTop + document.body.clientHeight}px`;
        });
      } else {
        document.body.style.overflow = 'visible';
        this.$refs.bg.style.height = '100%';
      }
    },
  },
  methods: {
    isActive(menu) {
      return isCurrPath(menu.path)
    },
    jump(menu) {
      const path = tools.getMatch(location.pathname)
      vkTrack.click(`parent_h5_web_${path}_click`)
      setTimeout(() => {
        jumpPath(menu.path)
      }, 300)
    },
    jumpHome() {
      jumpPath('/home')
    },
    handleScroll() {
      const headerHeight = this.$refs.nav.offsetHeight;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > headerHeight) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
      this.$refs.navMenu && (this.$refs.navMenu.style.paddingTop = scrollTop + 20 + 'px')
    },
    goLogin(){
      let url = ''
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        let qa = /^(http:|https:|\s)\/\/([a-zA-Z0-9-]{0,100}).vipkid-qa.com.cn\//i
        if (qa.test(window.location.href)) {
          url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd6458b851c9d175d&redirect_uri=http://stage-weixin.vipkid-qa.com.cn/oauth/validateBind&response_type=code&scope=snsapi_base&state=${encodeURIComponent('/entry/h5logincenter?isFromWechat=true&appId=10014')}#wechat_redirect`
        } else {
          url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbd56495f131c90a8&redirect_uri=http://weixin.vipkid.com.cn/oauth/validateBind&response_type=code&scope=snsapi_base&state=${encodeURIComponent('/entry/h5logincenter?isFromWechat=true&appId=10014')}#wechat_redirect`
        }
      } else {
        url = '/login?isFromWechat=true'
      }
      window.location.href = url
    },
    goSignup() {
      let url = ''
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        let qa = /^(http:|https:|\s)\/\/([a-zA-Z0-9-]{0,100}).vipkid-qa.com.cn\//i
        if (qa.test(window.location.href)) {
          url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd6458b851c9d175d&redirect_uri=http://stage-weixin.vipkid-qa.com.cn/oauth/validateBind&response_type=code&scope=snsapi_base&state=${encodeURIComponent('/entry/h5logincenter?isFromWechat=true&target=signup&appId=10014')}#wechat_redirect`
        } else {
          url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbd56495f131c90a8&redirect_uri=http://weixin.vipkid.com.cn/oauth/validateBind&response_type=code&scope=snsapi_base&state=${encodeURIComponent('/entry/h5logincenter?isFromWechat=true&target=signup&appId=10014')}#wechat_redirect`
        }
      } else {
        url = '/login?isFromWechat=true&target=signup'
      }
      window.location.href = url
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style scoped lang="less">
@keyframes showNav{
  0%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
@keyframes showMenu {
  0% {
    height: 0;
  }
  100% {
    height: 432px;
  }
}
.nav{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 15px 16px 8px 20px;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  .right {
    display: flex;
  }
}
.pb4{
  padding-bottom: 4px;
}
.showNav{
  z-index: 60;
  animation: showNav 0.25s ease-in 1 forwards;
}
.logo{
  width: 80px;
  height: 37px;
}
.menu{
  width: 28px;
  height: 28px;
  margin-top: 5px;
}
.nav-menu{
  background-color: #fff;
  padding: 15px 0 0 20px;
  border-radius: 0 0 16px 16px;
  .active{
    color: rgba(248, 84, 21, 1);
  }
  animation: showMenu 0.25s ease-out 1 forwards;
}
.nav-menu a {
  color: rgba(47, 51, 56, 1);
  font-size: 16px;
  line-height: 24px;
  padding: 12px 0;
  display: block;
}
.bg{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.btn{
  width: 52px;
  height: 28px;
  line-height: 28px;
  border-radius: 8px;
  text-align: center;
  margin-top: 6px;
  display: inline-block;
}
.login{
  border: 1px solid rgba(227, 229, 232, 1);
  color: rgba(47, 51, 56, 1);
  margin-right: 16px;
}
.register{
  background-color: rgba(248, 84, 21, 1);
  color: rgba(255, 255, 255, 1);
  margin-right: 16px;
  margin-left: 8px;
}
</style>
