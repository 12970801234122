<template>
  <div class="comp-video-player">
    <video ref="video" controls :src="src" :poster="poster"></video>
  </div>
</template>

<script>
import vkTrack from '@/utils/_vktrack'

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    poster: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    }
  },
  mounted() {
    this.$eventbus.$on('stopVideo', () => {
      this.$refs.video && this.$refs.video.pause()
    })
    this.$refs.video.addEventListener('play', () => {
      vkTrack.click(`parent_h5_video_${this.name}_click`)
    })
  }
}
</script>

<style lang="less" scoped>
  .comp-video-player {
    video {
      width: 100%;
      border-radius: 16px;
    }
  }
</style>