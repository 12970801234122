<template>
  <div class="part get">
    <div class="part-content">
      <div class="get-entry">
        <img v-if="type === '1'" src="./img/get1.png" />
        <img v-if="type === '2'" src="./img/get2.png" />
        <img v-if="type === '3'" src="./img/get3.png" />
        <img v-if="type === '4'" src="./img/get4.png" />
        <img v-if="type === '5'" src="./img/get5.png" />
        <img v-if="type === '6'" src="./img/get6.png" />
        <div class="entry-btn" @click="handleShowEntry"></div>
      </div>
    </div>
    <loginIframe v-if="showLogin" @close="handleClose" :isPopup="true" :channelId="channelId" :type="1"></loginIframe>
  </div>
</template>

<script>
import loginIframe from '@/components/loginIframe.vue';
import vkTrack from '@/utils/_vktrack'
import tools from '@/utils/tools'

export default {
  data(){
    return {
      showLogin: false
    }
  },
  props: {
    type: {
      type: String,
      default: '1'
    },
    channelId: {
      type: String,
      required: true
    },
    index: {
      type: String,
      default: '1'
    }
  },
  components:{
    loginIframe
  },
  methods: {
    handleShowEntry() {
      const path = tools.getMatch(location.pathname)
      vkTrack.click(`${path}_parent_h5_free-charge-btn-click_${this.index}`)
      this.showLogin = true
      document.body.style.overflow = 'hidden'
    },
    handleClose() {
      this.showLogin = false
      document.body.style.overflow = 'visible'
    }
  }
}
</script>

<style lang="less" scoped>
.part.get {
  .part-content {
    margin: 50px 0;
    padding: 0 16px;
  }
  .get-entry {
    position: relative;
    img {
      width: 100%;
    }
    .entry-btn {
      width: 180px;
      height: 60px;
      position: absolute;
      top: 80px;
      right: 22px;
    }
  }
}
</style>
