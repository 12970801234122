import { render, staticRenderFns } from "./loginIframe.vue?vue&type=template&id=3501ab77&scoped=true"
import script from "./loginIframe.vue?vue&type=script&lang=js"
export * from "./loginIframe.vue?vue&type=script&lang=js"
import style0 from "./loginIframe.vue?vue&type=style&index=0&id=3501ab77&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3501ab77",
  null
  
)

export default component.exports