<template>
  <div class="part teacher">
    <div class="title">国际化优秀教师</div>
    <div class="part-content">
      <div class="desc">为全球语言学习者提供高品质教学服务</div>
      <img src="./img/stats2.png" class="stats" />
      <div class="teacher-list">
        <div v-for="(item, index) in teacherList" :key="index" class="teacher-item">
          <div class="top">
            <div class="left">
              <img :src="item.avatar" />
            </div>
            <div class="right">
              <div class="name">{{ item.name }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
          <div class="intro">
            {{ item.intro }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teacherList: [
        {
          avatar: require('./img/avatar1.png'),
          name: 'Teacher Claire GE',
          desc: '8+ years of teaching experience',
          intro: 'The teacher in me finds so much joy in this job! I have been teaching some of my students for more than four years, and I’ve built real connections with my students, and their families. I love these kids.'
        },
        {
          avatar: require('./img/avatar2.png'),
          name: 'Teacher Stephen BLOS',
          desc: ' 7.5+ years of teaching experience',
          intro: 'I love to work with students and to help them learn and grow. VIPKid is the most rewarding opportunity that I have had as an educator.'
        },
        {
          avatar: require('./img/avatar3.png'),
          name: 'Teacher Kym TS',
          desc: '9+ years of teaching experience',
          intro: 'I love the one-on-one connection between teachers and students. Though I prepare for every class, I love the VIPKid curriculum. All I have to do is bring my own “magic” to the lessons.'
        },
      ],
    }
  }
}
</script>

<style lang="less" scoped>
@import "../base.less";

.part.teacher {
  img.stats {
    width: 100%;
    position: absolute;
    top: 48px;
    z-index: -1;
  }
  .teacher-list {
    width: 100%;
    padding: 552px 16px 20px;
    box-sizing: border-box;
    .teacher-item {
      margin-top: 20px;
      padding: 16px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0px 20px 40px 0px rgba(15, 24, 44, 0.05);
      .top {
        .common-flex();
        .left {
          img {
            width: 73px;
            height: 76px;
          }
        }
        .right {
          margin-left: 16px;
          .name {
            .font-title();
          }
          .desc {
            margin-top: 7px;
            .font(15px, 400, #686868);
          }
        }
      }
      .intro {
        margin-top: 24px;
        line-height: 28px;
        .font-desc2();
      }
    }
  }
}
</style>