<template>
  <div class="login-cont" :class="[{'login-iframe': isPopup}]">
    <div v-if="!isLogin">
      <iframe ref="myIframe" :src="iframeSrc" class="iframe" :class="[{'block': showIframe}, {'fix': type === 1}]"></iframe>
      <img src="./img/close-pop.png" alt="" class="close-btn" @click="close" v-if="isPopup">
    </div>
    <div v-else class="succ-container" :class="[{'succ-pop': isLogin && isPopup}]">
      <h3>您已成功申请试听课</h3>
      <p>我们将尽快电话联系您，为您安排试听老师，请您保持电话畅通。</p>
      <div class="btn" @click="linkTo">好的</div>
    </div>
  </div>
</template>
<script>
import tools from '@/utils/tools'
export default {
  data(){
    return{
      iframeSrc: '',
      showIframe: false,
      isLogin: false // 是否登录
    }
  },
  props:{
    isPopup: {
      type: Boolean,
      required: true
    },
    channelId: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.initMessage()
    // 大咖传成人标识
    const path = tools.getMatch(location.pathname)
    let adultIdentify = 0
    if(path.includes('bigstar')) {
      adultIdentify = 1
    }
    this.iframeLoad()
    this.iframeSrc = `/entry/newwwwh5login?path=${path}&channel_id=${this.channelId}&adultIdentify=${adultIdentify}`
    // this.iframeSrc = `http://0.0.0.0:8080/entry/newwwwh5login?path=${path}&channel_id=${this.channelId}&adultIdentify=${adultIdentify}`
    this.$refs.myIframe.contentWindow.postMessage('bodyclick', '*')
    this.changeBottom()
    window.addEventListener('resize', () => {
      this.changeBottom()
    });
  },
  methods: {
    iframeLoad() {
      this.$refs.myIframe.style.height = 0
      this.$refs.myIframe.onload = () => {
        setTimeout(() => {
          if(this.type === 1) {
            this.$refs.myIframe.style.height = '322px'
          } else {
            this.$refs.myIframe.style.height = '334px'
          }
        }, 500)
      }
    },
    close() {
      this.$emit('close')
    },
    initMessage() {
      // 通信
      window.addEventListener('message', (event) => {
        let data = event.data
        //TODO 处理加载完成信息
        // 注册完成
        if (data && data.msg == 'entry/loaded') {
          this.showIframe = true
        }
        // 注册完成
        if (data.code == 200 && data.msg == 'entry/logincenter') {
          this.isLogin = true
          document.querySelector('.first').classList.add('second')
        }
      }, false)
    },
    linkTo(){
      window.location.href = '/parents/home'
    },
    changeBottom() {
      let box = document.querySelector('.close-btn');
      if(window.innerHeight < 700) {
        setTimeout(() => {
          if(box) {
            box.style.bottom = '20%'
          }
        })
      } else {
        setTimeout(() => {
          if(box) {
            box.style.bottom = '23%'
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="less">
.login-iframe{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.close-btn{
  position: fixed;
  bottom: 23%;
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
}
.iframe{
  display: none;
  border: none;
  margin:0;
  padding:0;
  border-radius: 16px;
  position: absolute;
  top: 24%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  width: 343px;
  height: 334px;
}
.fix{
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  height: 322px;
}
.block{
  display: block;
}
.succ-container{
  background-color: #fff;
  border-radius: 16px;
  text-align: center;
  margin: 0 16px;
  position: absolute;
  top: -7.5%;
  box-shadow: 0 5px 20px rgba(248, 84, 21, 0.15);
  h3{
    margin: 0;
    color: rgba(248, 84, 21, 1);
    font-size: 26px;
    line-height: 39px;
    padding-top: 10px;
    font-weight: 700;
  }
  p{
    padding: 0 10px;
    font-size: 15px;
    line-height: 22.5px;
    color: rgba(47, 51, 56, 1);
    font-weight: 500;
  }
  .btn{
    background-color: rgba(248, 84, 21, 1);
    color: #fff;
    border-radius: 8px;
    height: 46px;
    line-height: 46px;
    margin-top: 16px;
    font-size: 20px;
    font-weight: 500;
    margin: 0 13px 26px;
  }
}
.succ-pop{
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  width: 343px;
  margin: auto;
}
</style>