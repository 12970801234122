/**
 * ------------------------------------------------------------------
 * 打点 模块
 *
 * 集成click和trigger两种打点形式
 * ------------------------------------------------------------------
 */
import vktrack from 'sa-sdk-javascript';

const cookies = require('cookies-js');

vktrack.toolDebug = window.location.protocol !== 'https:';

vktrack.init({
  show_log: vktrack.toolDebug,
  server_url: 'https://sensorsdata.vipkid.com/sa?project=default', // 神策打点地址，测试环境为http://sc.vipkid.com.cn:8006/sa?project=test，线上环境为https://sensorsdata.vipkid.com/sa?project=default
});

vktrack.clearAllRegister(['platform']);

vktrack.register({
  parent_id: cookies.get('mbparentid') || '',
  student_id: cookies.get('mbstudentid') || '',
  client: isFromApp() ? 'app' : isFromWechat() ? 'wechat' : 'browser',
  business: 'parent_h5',
  product: 'parent',
  vk_session_id: cookies.get('vk_session_id') || '',
});

// 默认$pageview打点
vktrack.quick('autoTrack');

function isFromApp() {
  return cookies.get('isFromApp') === '1';
}

function isFromWechat() {
  return /MicroMessenger/i.test(navigator.userAgent);
}

function click(clickId, obj) {
  const obj2 = { ...obj, event_id_: clickId};
  vktrack.track('page_click_viptrack', obj2);
}

function trigger(triggerId, obj) {
  const obj2 = { ...obj, event_id_: triggerId};
  vktrack.track('page_trigger_viptrack', obj2);
}

export default {
  click,
  trigger,
};
